<template>
  <div>
    <b-form-row>
      <b-col sm="10" md="6" lg="5" xl="4">
        <b-form-group>
          <h4 class="page-sub-header">Action link text</h4>
          <div class="floating-input-field">
            <b-form-input
              id="action-link-text"
              :class="{
                'is-invalid': !data.actionLinkTxt && formSubmitted,
              }"
              type="text"
              placeholder=" "
              v-model="data.actionLinkTxt"
              required
            ></b-form-input>
            <label for="action-link-text">Action link text</label>
            <b-form-invalid-feedback class="d-block" v-if="!data.actionLinkTxt && formSubmitted">Action link text required.</b-form-invalid-feedback>
          </div>
        </b-form-group>
      </b-col>
    </b-form-row>
    <b-form-row>
      <b-col sm="10" md="6" lg="5" xl="4">
        <b-form-group>
          <h4 class="page-sub-header">Dashboard items</h4>
          <div class="floating-select-field">
            <b-form-select
              id="dashboard-items"
              class="form-control"
              :options="widgetDashboardCountList"
              v-model.number="data.dashboardItemCnt"
              @input="data.dashboardItemCnt = $event"
              :disabled="multilingualToggle"
              :class="{
                'is-value-exist': data.dashboardItemCnt != null,
                'is-invalid': !data.dashboardItemCnt && formSubmitted,
              }"
            >
            </b-form-select>
            <label for="dashboard-items">Dashboard items <InheritText v-if="multilingualToggle" /></label>
            <b-form-invalid-feedback class="d-block" v-if="!data.dashboardItemCnt && formSubmitted">Dashboard items required.</b-form-invalid-feedback>
          </div>
        </b-form-group>
      </b-col>
      <b-col cols="12" sm="6" md="6" lg="3" class="d-flex align-items-center"> Recommended: 3</b-col>
    </b-form-row>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { WidgetDashboardCountList } from '../../../utilities/constants'
export default {
  name: 'FormBuilderWidget',
  props: ['data', 'tenantId', 'formSubmitted', 'isEditPage', 'culture', 'defaultCulture'],
  data() {
    return {
      widgetDashboardCountList: WidgetDashboardCountList,
    }
  },
  computed: {
    multilingualToggle() {
      return this.isMultilingualSupportEnabled && this.isDefaultCulture
    },
    isDefaultCulture() {
      return this.culture !== this.defaultCulture
    },
    ...mapState({
      isMultilingualSupportEnabled: (state) => state.common.isMultilingualSupportEnabled,
    }),
  },
  components: {
    InheritText: () => import('@/components/common/InheritText.vue'),
  },
}
</script>
